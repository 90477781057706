<style scoped>
.listIngredients {
  height: 550px;
  overflow: scroll;
}

.cardDetailFood {
  min-height: 600px;
}

.boxlist {
  border-bottom: 1px solid #dfe0e1;
  cursor: pointer;
}

.boxlist:hover {
  background-color: #ededed;
}

.smalltext {
  font-size: 10px;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

/**
 * Profile component
 */
export default {
  components: { Layout, PageHeader },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    majuscule: function (value) {
      if (!value) return "";
      return (value = value.toString().toUpperCase());
    },
  },
  page: {
    title: "Food",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  mounted() {
    this.getAllFoods();
  },
  data() {
    return {
      title: "Food",
      saving_modification: false,
      saving_photo: false,
      adding_ingredient: false,
      add_ingredient: false,
      transloading_photo: false,
      ingredients: [],
      ingredient: null,
      uploadValue: 0,
      macros: ["proteine", "glucide", "lipide"],
      vitamines: [
        "a",
        "c",
        "d",
        "e",
        "k",
        "b1",
        "b2",
        "b3",
        "b5",
        "b6",
        "b9",
        "b12",
      ],
      mineraux: [
        "calcium",
        "phosphore",
        "magnesium",
        "potassium",
        "sodium",
        "fer",
        "cuivre",
        "zinc",
        "mangenese",
        "selenium",
        "iode",
      ],
      categories: [
        "vegetable",
        "Fruit",
        "Starchy food",
        "Dairy",
        "Protein",
        "Fat",
        "Supplement",
        "Drinks",
      ],
      langues: ["it", "fr", "us", "es", "br"],
      items: [
        {
          text: "Food",
        },
        {
          text: "Food",
          active: true,
        },
      ],
    };
  },
  methods: {
    async transloadPhoto() {
      this.transloading_photo = true;

      var url = this.ingredient.data.photo.original;

      var data = {
        id: this.ingredient.id,
        url: url,
        type: "ingredients",
      };

      var transloadPhoto = firebase.functions().httpsCallable("transloadPhoto");
      transloadPhoto(data).then((result) => {
        // Read result of the Cloud Function.
        console.log(result);
        this.ingredient.data.photo = result.data.photo;
        firebase.firestore().collection('ingredients').doc(data.id).update(this.ingredient.data);
        this.transloading_photo = false;
      });
    },
    async transferMediaGoogle() {
      this.saving_photo = true;

      var newPhoto = document.getElementById("newPhoto").files[0];
      console.log(newPhoto);
      var type = newPhoto.name.split(".").pop();
      var filename = "ingredients/" + this.ingredient.id + "-" + Date.now() + "." + type;

      const storageRef = firebase
        .app()
        .storage("gs://gabriellavicofitness")
        .ref(filename)
        .put(newPhoto);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            var newPhoto = {
              original: url,
              optimized: null,
              thumbnail: null,
            };
            var db = firebase.firestore();
            db.collection("ingredients")
              .doc(this.ingredient.id)
              .update({ photo: newPhoto })
              .then(() => {
                console.log("ingredient mis a jour");
                this.ingredient.data.photo = newPhoto;
                this.saving_photo = false;
                this.uploadValue = 0;
                document.getElementById("newPhoto").value = "";
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          });
        }
      );
    },
    saveModificationsIngredient() {
      //On recupère toutes les valeurs

      this.saving_modification = true;

      var db = firebase.firestore();
      db.collection("ingredients")
        .doc(this.ingredient.id)
        .update(this.ingredient.data)
        .then(() => {
          console.log("Document successfully written!");
          this.saving_modification = false;
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    addNewIngredient() {
      this.adding_ingredient = true;

      var db = firebase.firestore();
      db.collection("ingredients")
        .doc(this.ingredient.id)
        .set(this.ingredient.data)
        .then(() => {
          this.adding_ingredient = false;
          this.add_ingredient = false;
          this.ingredients = [];
          this.ingredient = null;
          this.getAllFoods();
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    getPhotoIngredientThumb(ingredient) {
      if (ingredient.data.photo.thumbnail != null)
        return ingredient.data.photo.thumbnail;
      else return require("@/assets/images/nophoto.png");
    },
    getPhotoIngredient(ingredient) {
      if (ingredient.data.photo.original != null)
        return ingredient.data.photo.original;
      else return require("@/assets/images/nophoto.png");
    },
    searchIngredient() {
      var search = document.getElementById("searchfood").value.toLowerCase();
      var category = document.getElementById("searchCategory").value;
      //filtrer en javascript

      document
        .querySelectorAll("[data-type='ingredient']")
        .forEach((element) => {
          var id_ingredient = element.dataset.id;
          //On récupère le JSON de l'ingredient avec son id
          let ingredient = this.ingredients.find(
            (el) => el.id === id_ingredient
          );
          //On regarde si la chaine de caractère est comprise dans le nom d'une des langues pour afficher ou masquer
          if (
            (ingredient.data.description.it.nom
              .toLowerCase()
              .includes(search) ||
              ingredient.data.description.fr.nom
                .toLowerCase()
                .includes(search) ||
              ingredient.data.description.us.nom
                .toLowerCase()
                .includes(search) ||
              search == "") &&
            (ingredient.data.category == category || category == "all")
          )
            element.style = "display: block";
          else element.style = "display: none";
        });
    },
    getAllFoods() {
      var db = firebase.firestore();
      db.collection("ingredients")
        .orderBy("description.it.nom")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.ingredients.push({ id: doc.id, data: doc.data() });
          });
        });
    },
    setIngredient(ingredient) {
      this.add_ingredient = false;
      this.ingredient = ingredient;
    },
    showAddIngredient() {
      var vitamines = {};
      this.vitamines.forEach((vitamine) => {
        vitamines[vitamine] = 0;
      });

      var mineraux = {};
      this.mineraux.forEach((mineral) => {
        mineraux[mineral] = 0;
      });

      var json_ingredient = {
        category: null,
        solide: false,
        photo: {
          original: null,
          optimized: null,
          thumbnail: null,
        },
        description: {
          it: {
            nom: "",
            description: "",
            affiliation: "",
          },
          fr: {
            nom: "",
            description: "",
            affiliation: "",
          },
          us: {
            nom: "",
            description: "",
            affiliation: "",
          },
          es: {
            nom: "",
            description: "",
            affiliation: "",
          },
          pt: {
            nom: "",
            description: "",
            affiliation: "",
          },
        },
        proteine: 0,
        glucide: 0,
        lipide: 0,
        micros: {
          vitamines: vitamines,
          mineraux: mineraux,
        },
      };
      this.ingredient = {
        type: "new",
        id: String(Date.now()),
        data: json_ingredient,
      };
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-3 pt-2 pb-0 card" style="min-height: 650px">
          <div class="mb-2">
            <div class="d-grid gap-2">
              <button
                class="btn btn-primary"
                type="button"
                @click="showAddIngredient()"
              >
                + Add ingredient
              </button>
            </div>
            <hr />
          </div>
          <div class="mb-0">
            <input
              type="text"
              class="form-control"
              id="searchfood"
              placeholder="Search ingredient..."
              @keyup="searchIngredient()"
            />
            <select
              class="form-select mt-2"
              aria-label="Default select example"
              @change="searchIngredient()"
              id="searchCategory"
            >
              <option value="all" selected>All categories</option>
              <option
                v-for="category in categories"
                :key="'categorysearch' + category"
                :value="category"
              >
                {{ category }}
              </option>
            </select>
            <div class="alert alert-secondary mt-2 px-2 py-1" role="alert">
              <small>{{ ingredients.length }} ingredients</small>
            </div>
            <div class="listIngredients">
              <div
                v-for="(ingredient, key) in ingredients"
                :key="'food' + key"
                :id="'foodid' + key"
                :data-id="ingredient.id"
                data-type="ingredient"
                @click="setIngredient(ingredient)"
              >
                <div class="container boxlist px-0 py-2">
                  <div class="row">
                    <div class="col-md-auto">
                      <img
                        width="60px"
                        :src="getPhotoIngredientThumb(ingredient)"
                        alt
                        class="img-thumbnail"
                      />
                    </div>
                    <div class="col">
                      <p class="fw-bold mb-0">
                        {{ ingredient.data.description.it.nom }}
                      </p>
                      <p class="fw-light smalltext mb-0">
                        Prot.: {{ ingredient.data.proteine }}g | Gluc.:
                        {{ ingredient.data.glucide }}g | Lip:
                        {{ ingredient.data.lipide }}g
                      </p>
                      <div>
                        <div
                          v-for="(value, name, key) in ingredient.data
                            .description"
                          :key="'infodescription' + key"
                          class="d-inline p-1"
                        >
                          <img
                            :src="'https://flagcdn.com/h20/' + name + '.png'"
                            height="12"
                            :style="
                              ingredient.data.description[name].nom == '' ||
                              ingredient.data.description[name].nom == null
                                ? 'opacity: 0.3'
                                : 'opacity: 1'
                            "
                          />
                          <div
                            v-if="value.affiliation"
                            class="d-inline p-1 pr-2"
                          >
                            <i class="fas fa-link"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="card cardDetailFood">
            <div v-if="ingredient == null" class="text-center">
              Select ingredient
            </div>
            <div v-if="ingredient != null" class="p-3">
              <div class="container px-0 py-2">
                <div class="row">
                  <div class="col-2">
                    <img
                      width="100%"
                      :src="getPhotoIngredient(ingredient)"
                      alt
                      class="img-thumbnail"
                    />
                    <ul>
                      <li><small>Original: <a :href="ingredient.data.photo.original" target="_blank">{{ ingredient.data.photo.original }}</a></small></li>
                      <li><small>Optimized: <a :href="ingredient.data.photo.optimized" target="_blank">{{ ingredient.data.photo.optimized }}</a></small></li>
                      <li><small>Thumbnail: <a :href="ingredient.data.photo.thumbnail" target="_blank">{{ ingredient.data.photo.thumbnail }}</a></small></li>
                    </ul>
                    <div
                      class="d-grid gap-2 mt-2"
                      v-if="ingredient.type != 'new'"
                    >
                      <label for="formFile" class="form-label"
                        >Change photo:</label
                      >
                      <input class="form-control" type="file" id="newPhoto" />
                      <progress
                        v-if="saving_photo"
                        id="progress"
                        :value="uploadValue"
                        max="100"
                      ></progress>
                      <button
                        class="btn btn-primary btn-sm"
                        type="button"
                        id="uploadPhoto"
                        @click="transferMediaGoogle(ingredient.id)"
                      >
                        <span
                          v-if="saving_photo"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Update photo
                      </button>
                      <div
                        v-if="ingredient.data.photo.original != null"
                        class="d-grid gap-2 mt-2"
                      >
                        <button
                          class="btn btn-info btn-sm"
                          type="button"
                          id="uploadPhoto"
                          @click="transloadPhoto()"
                        >
                          <span
                            v-if="transloading_photo"
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Transload photo
                        </button>
                      </div>

                    </div>
                  </div>
                  <div class="col-10">
                    <div class="container">
                      <div class="row">
                        <div class="col-8">
                          <h1 class="mb-0">
                            {{ ingredient.data.description.it.nom }}
                          </h1>
                          <small>Ingredient #{{ ingredient.id }}</small>
                        </div>
                        <div class="col-4">
                          <button
                            v-if="ingredient.type != 'new'"
                            type="button"
                            class="btn btn-success btn-lg float-end"
                            @click="saveModificationsIngredient()"
                          >
                            <span
                              v-if="saving_modification"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Save modifications
                          </button>
                          <button
                            v-if="ingredient.type == 'new'"
                            type="button"
                            class="btn btn-success btn-lg float-end"
                            @click="addNewIngredient()"
                          >
                            <span
                              v-if="adding_ingredient"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Add ingredient
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="alert alert-secondary" role="alert">
                      <div class="row">
                        <label class="col-auto col-form-label">Category</label>
                        <div class="col">
                          <b-form-select
                            v-model="ingredient.data.category"
                            :options="categories"
                            id="type_recette"
                            class="mb-3"
                            value-field="type"
                            text-field="type"
                            disabled-field="notEnabled"
                          ></b-form-select>
                        </div>
                      </div>
                    </div>

                    <div class="container px-0 py-2">
                      <div class="row">
                        <b-tabs content-class="mt-3">
                          <b-tab
                            v-for="(langue, key) in ingredient.data.description"
                            :key="'descriptiontab-' + key"
                            :active="key === 'it'"
                          >
                            <template slot="title">
                              <img
                                :src="'https://flagcdn.com/h20/' + key + '.png'"
                                height="12"
                              />
                              {{ key }}
                            </template>
                            <div class="mb-3">
                              <label for="add-nom_it" class="form-label"
                                >Name*</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                :id="'nom_' + key"
                                v-model="ingredient.data.description[key].nom"
                              />
                            </div>
                            <div class="mb-3">
                              <label
                                :for="'description_' + key"
                                class="form-label"
                                >Description*</label
                              >
                              <textarea
                                class="form-control"
                                :id="'description_' + key"
                                rows="3"
                                v-model="
                                  ingredient.data.description[key].description
                                "
                              ></textarea>
                            </div>
                            <div class="mb-3">
                              <label
                                :for="'affiliation_' + key"
                                class="form-label"
                                >Link affiliation*</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                :id="'affiliation_' + key"
                                v-model="
                                  ingredient.data.description[key].affiliation
                                "
                              />
                            </div>
                          </b-tab>
                        </b-tabs>
                      </div>
                    </div>
                    <hr />
                    <div class="container px-0 py-2">
                      <div class="row">
                        <div class="col">
                          <div class="alert alert-warning" role="alert">
                            <h5>Solide?</h5>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="solide"
                                v-model="ingredient.data.solide"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                Solide?
                              </label>
                            </div>
                          </div>
                          <div class="alert alert-warning" role="alert">
                            <h5>Macro-nutriments</h5>
                            <hr />
                            <div class="row">
                              <div
                                v-for="(macro, key) in macros"
                                :key="'macro' + key"
                              >
                                <div class="mb-3 row">
                                  <label class="col-4 col-form-label"
                                    >{{ macro | capitalize }}*</label
                                  >
                                  <div class="col-8">
                                    <input
                                      type="number"
                                      class="form-control"
                                      :id="macro"
                                      v-model="ingredient.data[macro]"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h5>Mineraux</h5>
                          <hr />
                          <div class="row">
                            <div
                              v-for="(mineral, key) in mineraux"
                              :key="'mineraux' + key"
                            >
                              <div class="mb-3 row">
                                <label class="col-4 col-form-label">{{
                                  mineral | capitalize
                                }}</label>
                                <div class="col-5">
                                  <input
                                    type="number"
                                    class="form-control"
                                    :id="mineral"
                                    v-model="
                                      ingredient.data.micros.mineraux[mineral]
                                    "
                                  />
                                </div>
                                <div class="col-3">mg</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <h5>Vitamines</h5>
                          <hr />
                          <div class="row">
                            <div
                              v-for="(vitamine, key) in vitamines"
                              :key="'vitamine' + key"
                            >
                              <div class="mb-3 row">
                                <label class="col-4 col-form-label"
                                  >Vitamine {{ vitamine | majuscule }}</label
                                >
                                <div class="col-5">
                                  <input
                                    type="text"
                                    class="form-control"
                                    :id="'vitamine-' + vitamine"
                                    v-model="
                                      ingredient.data.micros.vitamines[vitamine]
                                    "
                                  />
                                </div>
                                <div class="col-3">mg</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
